<template>
    <div class="LookMaterialsList">
        <el-page-header @back="backPrivious" content="我在找料"></el-page-header>
        <div class="breadcrumb">
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/CommunityIndex' }">社区</el-breadcrumb-item>
                <el-breadcrumb-item>我在找料</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="f-c-e mt-30">
            <img class="publishBtn" @click="topublish" src="@/assets/Community/IndustryTrends/publishBtn.png" alt="">
        </div>
        <div class="list" v-if="LookMaterialsList && LookMaterialsList.length">
            <div class="Item flex" v-for="(item, index) of LookMaterialsList" :key="index"
                @click="toproductCommunDetail(item.postId)">

                <div class="info f-c-b">
                    <div class="w--100">
                        <div class="f-c mb-30">
                            <img class="img" :src="item.userAvatarUrl" alt="">
                            <div>
                                <div class="f-c mb-10">
                                    <div class="userName">{{ item.userName }}</div>
                                    <div class="ml-10 f-c" v-for="(item2, index2) of item.productTypeList"
                                        :key="index2">
                                        <div class="cornermark">
                                            {{ item2 }}
                                        </div>
                                    </div>
                                </div>
                                <div class="createDate">{{ item.createDate }}</div>
                            </div>


                        </div>
                        <div class="postHead">{{ item.postHead }}</div>
                        <div class="previewContent">{{ item.previewContent }}</div>
                    </div>

                    <div v-if="item.mobject1" class="mobject ml-20">
                        <img :src="item.mobject1" alt="">
                    </div>
                </div>

            </div>
        </div>

        <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout"
            @pagination="fetchData" />
    </div>
</template>

<script>


import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
import { UnitAccount } from "@/utils/attributeValue1Options";
export default {
    name: 'LookMaterialsList',
    mixins: [PaginationRetention],
    data() {
        return {
            LookMaterialsList: [],
        }
    },
    created() {
        // this.findForumMediumState();
    },
    methods: {
        getDate() {
            this.forumBuySearchList(this.paging, this.pagingSize,);
        },
        // 获取产品交流帖子列表
        forumBuySearchList(page = this.page, size = this.size) {
            let that = this;
            that.$http.forumBuySearchList({
                postUserId: parseInt(that.$store.state.userId),
                page,
                size
            }).then(res => {
                if (res.code == 200) {
                    let records = res.data.records;
                    records.forEach(item => {
                        let arr = item.productType.split(',');
                        let productTypeList = [];
                        arr.forEach(item2 => {
                            productTypeList.push(UnitAccount.getproductType(item2))
                            item.productTypeList = productTypeList
                        });
                    });
                    that.LookMaterialsList = records;
                    that.currentPage = res.data.current;//当前页数
                    that.total = res.data.total;
                }
            })
        },
        //  跳转到帖子详情
        toproductCommunDetail(postId) {
            this.$router.push({
                name: 'LookMaterialsDetail', query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        postId,
                    }))
                }
            })
        },
        // 
        topublish() {
            this.$router.push({
                name: 'LookMaterialsPost'
            });
        },

    },
}

</script>
<style lang='less' scoped>
.LookMaterialsList {
    .breadcrumb {
        padding: 10px 0;
        border-bottom: 1px solid #FBFBFB;
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .publishBtn {
        cursor: pointer;
    }

    .list {
        margin-top: 30px;

        .Item {
            border-radius: 20px;
            background: #F8F8F8;
            padding: 30px 75px;
            margin-bottom: 20px;
            cursor: pointer;



            .info {
                width: 100%;
                position: relative;

                .img {
                    height: 55px;
                    width: 55px;
                    border-radius: 50%;
                    margin-right: 15px;

                }

                .userName {
                    font-size: 20px;
                    color: #333333;
                    line-height: 20px;
                }

                .createDate {
                    margin-left: 10px;
                    font-size: 16px;
                    color: #0363FA;
                    line-height: 20px;
                }

                .cornermark {
                    border-radius: 5px;
                    background: #E6F1FE;
                    text-align: center;
                    line-height: 16px;
                    font-size: 16px;
                    color: #0573F2;
                    padding: 5px;
                }

                .postHead {
                    width: 100%;
                    font-weight: 500;
                    font-size: 24px;
                    color: #333333;
                    margin-bottom: 15px;
                }

                .previewContent {
                    font-size: 16px;
                    color: #666666;
                    margin-bottom: 15px;
                }

                .mobject {
                    img {
                        border-radius: 20px;
                        height: 200px;
                        width: 200px;
                    }

                }

                .comments {
                    img {
                        width: 20px;
                        height: 20px;
                        margin-right: 8px;
                    }

                    font-size: 16px;
                    color: #999999;
                }
            }
        }
    }
}
</style>